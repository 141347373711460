import React from "react"
import { css } from "styled-components"

export default function Circle({
  children,
  items = [],
  circleSize = 40,
  itemSize = 6,
}) {
  function cal() {
    let styles = ""
    const angle = 360 / items.length
    let rotation = -90

    for (let i = 1; i <= items.length; i++) {
      styles += `
        &:nth-of-type(${i}) {
          transform: rotate(${rotation * 1}deg) translate(${circleSize / 2}rem)
            rotate(${rotation * -1}deg);
        }
      `

      rotation = rotation + angle
    }

    return css`
      ${styles}
    `
  }

  return (
    <div
      css={`
        position: relative;
      `}
    >
      <div
        css={`
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        `}
      >
        {children}
      </div>
      <ul
        css={`
          position: relative;
          width: ${circleSize}rem;
          height: ${circleSize}rem;
          border-radius: 50%;

          > * {
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            width: ${itemSize}rem;
            height: ${itemSize}rem;
            margin: -${itemSize / 2}rem;

            ${cal()}
          }
        `}
      >
        {items.map(item => (
          <li key={item.id}>{item.render()}</li>
        ))}
      </ul>
    </div>
  )
}
