import React, { useState } from "react"
import Container from "../virtual-lab/Container"
import RawCircle from "../common/Circle"
import { BREATH_ANALYSIS_ITEMS } from "../../config"
import { StaticImage } from "gatsby-plugin-image"
import CircleItem from "./CircleItem"
import CircleDescription from "./CircleDescription"
import Button from "../common/Button"
import IconDownload from "../../assets/images/icon-download.inline.svg"
import { Text } from "../common/Typography"
import { Link } from "gatsby"

const Title = props => (
  <Container.Title width="56rem" {...props}>
    The power of breath analysis
  </Container.Title>
)

const Description = props => (
  <Container.Description width="70rem" {...props}>
    Oxygen is the molecule of life. That's why every core organ, like the heart,
    lungs, and cells, is involved in transferring or using oxygen. By scanning
    your breath, we analyze the flow of oxygen through these organs. Our
    analysis then reveals insights such as the energy cells use, their
    efficiency in burning fats and carbohydrates, heart and lungs fitness,
    exercise recovery capacity, and how breath affects brain and blood
    circulation.
  </Container.Description>
)

function Circle({ size = "default", activeItem, onItemClick, ...props }) {
  return (
    <div
      css={`
        margin: 6.4rem 0;
      `}
    >
      <RawCircle
        items={BREATH_ANALYSIS_ITEMS.map(item => ({
          id: item.id,
          render: () => (
            <CircleItem
              type="alt"
              size={size === "mobile" && "4rem"}
              isActive={activeItem.id === item.id}
              onClick={() => onItemClick(item)}
              item={item}
            />
          ),
        }))}
        circleSize={size === "mobile" ? 30 : 40}
        itemSize={size === "mobile" ? 5 : 10}
        {...props}
      >
        <StaticImage
          src="../../assets/images/the-science/breath-analysis.png"
          alt="oxygen"
          width={250}
          placeholder="tracedSVG"
        />
      </RawCircle>
    </div>
  )
}

function Download(props) {
  return (
    <Button
      css={`
        padding: 8px 16px;
      `}
      as="a"
      href="/literature.zip"
      download
      {...props}
    >
      <div
        css={`
          display: flex;
          align-items: center;
          justify-content: center;
        `}
      >
        Download the literature
        <IconDownload
          css={`
            margin-left: 11px;
            fill: #0095ff;
          `}
        />
      </div>
    </Button>
  )
}

function LearnMore(props) {
  return (
    <Text
      as={Link}
      to="/blog/breath-analysis-the-most-complete-picture-of-your-body"
      size="base"
      weight="bold"
      css={`
        line-height: 1.5;
        color: rgb(0, 149, 255);
        text-decoration: underline;
      `}
      {...props}
    >
      Learn More
    </Text>
  )
}

function SectionText({ activeItem }) {
  return (
    <div
      css={`
        max-width: 42rem;
        min-height: 30rem;
      `}
    >
      <CircleDescription {...activeItem} />
      <div
        css={`
          margin-top: 3.2rem;
          display: flex;
          align-items: center;
          justify-content: space-between;
        `}
      >
        <Download />
        <LearnMore />
      </div>
    </div>
  )
}

function useActiveItem() {
  return useState(BREATH_ANALYSIS_ITEMS[0])
}

export default function BreathAnalysis() {
  const [activeItem, setActiveItem] = useActiveItem()

  return (
    <Container
      bleed="both"
      py="7rem"
      css={`
        flex-direction: column;
        text-align: center;
      `}
    >
      <Title mb={24} />
      <Description />
      <div
        css={`
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
        `}
      >
        <Circle
          activeItem={activeItem}
          onItemClick={item => setActiveItem(item)}
        />
        <SectionText activeItem={activeItem} />
      </div>
    </Container>
  )
}

function Mobile() {
  const [activeItem, setActiveItem] = useActiveItem()

  return (
    <Container bleed="both">
      <Title />
      <Description />
      <Circle
        activeItem={activeItem}
        onItemClick={item => setActiveItem(item)}
        size="mobile"
      />
      <CircleDescription {...activeItem} />
      <Download mt={32} />
      <LearnMore mt={32} />
    </Container>
  )
}

BreathAnalysis.Mobile = Mobile
